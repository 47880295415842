<template>
  <div class="fill-height">
    <div class="box" v-if="isFetched">
      <div class="main-row navbar" :class="{ 'no-print': isCardInfoPrint }">
        <the-navbar />
      </div>
      <div class="main-row content">
        <sider-menu />
        <div
          class="box map-overlay"
          :class="[
            { 'print-main': isQuickPrint },
            { 'no-print': isCardInfoPrint },
            isSidebarVisible ? 'map-legend-visible' : 'map-legend-hidden',
          ]"
          no-gutters
        >
          <div
            style="max-width: 100%; position: relative; height: 100%"
            :style="isQuickPrint ? 'z-index: 4' : 'z-index: 2'"
          >
            <the-map ref="map" style="overflow-y: hidden" />
            <div class="printable-map-wrapper"></div>
          </div>
          <div
            v-if="isLayer"
            style="max-width: 100%; min-height: 0; flex-shrink: 0"
            :style="{ height: tableRowHeight }"
          >
            <table-height-buttons />
            <router-view ref="table" name="table"></router-view>
          </div>
        </div>
        <div style="width: 0" :class="{ 'no-print': isQuickPrint || isCardInfoPrint }">
          <hide-sidebar-button />
        </div>
        <div class="legend" :class="isSidebarVisible ? 'legend__visible' : 'legend__hidden'">
          <the-sidebar v-show="isSidebarVisible" />
        </div>
      </div>
    </div>
    <div v-else class="centerTrick">
      <main-loader></main-loader>
    </div>
  </div>
</template>

<script>
import { get, call, sync } from 'vuex-pathify';

import TheMap from '@/components/TheMap';
import TheNavbar from '@/components/TheNavbar';
import TheSidebar from '@/components/TheSidebar';
import MainLoader from '@/components/MainLoader';
import HideSidebarButton from '@/components/HideSidebarButton.vue';
import filters from '@/mixins/filters';
import shortcuts from '@/mixins/shortcuts';
import TableHeightButtons from '@/components/TableHeightButtons';
import SiderMenu from '@/components/SiderMenu';

export default {
  name: 'MainView',
  components: {
    TheMap,
    TheNavbar,
    TheSidebar,
    MainLoader,
    SiderMenu,
    HideSidebarButton,
    TableHeightButtons,
  },
  mixins: [filters, shortcuts],
  data: () => ({
    isTriedDefault: false,
    isLayerRoutesNames: [
      'addingLamp',
      'fieldCalculator',
      'layerTable',
      'layerFeature',
      'failures',
      'lockAnalysis',
      'newFailure',
      'failure',
      'zdm_failure',
      'zdm_failures',
      'cutoffLayerTable',
      'tasks',
      'task',
      'transmission_easement',
      'transmission_easements',
      'folders',
      'folder',
      'hydrantInspection',
      'legalization',
      'legalizationInfo',
      'waterDissolutionDma',
      'waterDissolutionDmaInfo',
      'waterDissolutionWatermeters',
      'waterDissolutionWatermetersInfo',
      'investments',
      'investment',
      'billingAnomalyDetection',
      'billingAnomalyDetectionInfo',
      'locationLabReportsLayer',
      'zdmCustomReportsLayer',
      'sewerDataInvertedGradientLayer',
      'sewerDataDownstreamLayer',
      'sewerDataUpstreamLayer',
      'modernization',
      'locationSelectionLayer',
      'fireSimulationLayer',
      'locksAnalysisLayer',
      'buffer',
      'attributesPainterFeatures',
      'attributesPainter',
      'attributesPainter',
      'unassignedWatermeters',
      'upstreamSewageVolumeLayer',
      'upstreamSewageOutpouringLayer',
      'usableAreaLayer',
      'circularSectorSelection',
      'antennaSignalDetermination',
      'preciseDigitizing',
    ],
  }),
  computed: {
    mapPanelDataFetched: sync('map/mapPanelDataFetched'),
    mapRelationDataFetched: sync('map/mapRelationDataFetched'),
    isFetched: sync('sidebar/isFetched'),
    isSidebarVisible: sync('sidebar/isVisible'),
    isProjectLayersFetched: sync('sidebar/isProjectLayersFetched'),
    tableHeight: get('table/tableHeight'),
    projectElements: get('layers/project@layers'),
    projectLayers() {
      return this.$_getFlatGroupsLayers(this.projectElements).layers;
    },
    layersFilters: get('layers/layersFilters'),
    permissions: get('user/permissions'),
    failuresLayerId: get('admin/modulesMapping@failures.layer_id'),
    project: sync('layers/project'),
    projectId: sync('layers/projectId'),
    projects: get('layers/projects'),
    currentUserId: sync('users/currentUser@id'),
    featuresLayers: get('layers/featuresLayers'),
    easementsLayerId: get('admin/modulesMapping@transmission_easement.layer_id'),
    currentLayer: sync('layers/currentLayer'),
    appName: get('admin/settingsValues@app_name'),
    isQuickPrint: get('tools/toolStatus@isQuickPrintToolActive'),
    isCardInfoPrint: get('tools/toolStatus@isCardInfoPrintActive'),
    isLayer() {
      return this.isLayerRoutesNames.includes(this.$route.name);
    },
    tableRowHeight() {
      const dict = {
        0: 'auto',
        1: '33%',
        2: '50%',
        3: '100%',
      };
      return dict[this.tableHeight];
    },
    hasProjectQuery() {
      return this.queryProjectId !== undefined;
    },
    queryProjectId() {
      return this.$route.query.project;
    },
    routeName() {
      return this.$route.name;
    },
    layerId() {
      return +this.$route.params.lid;
    },
    isCacheLayersAdminEnabled: get('admin/settingsValues@cache_layer_module_enabled'),
    isCacheLayersSuperAdminEnabled: get('admin/additionalModules@CACHE_LAYER_MODULE.enabled'),
    modulesPermissions: get('users/toolsPermissions'),
    isSiderExpanded: get('sider/isExpanded'),
    cacheLayersPermissions() {
      return this.modulesPermissions['CACHE_LAYER_MODULE'].main_value > 0;
    },
    isCacheLayersEnabled() {
      return this.isCacheLayersSuperAdminEnabled && this.isCacheLayersAdminEnabled && this.cacheLayersPermissions;
    },
  },
  watch: {
    tableHeight() {
      this.$refs.map.updateSize();
    },
    isLayer() {
      this.$refs.map?.updateSize();
    },
    isSidebarVisible() {
      this.$refs.map.updateSize();
    },
    isSiderExpanded() {
      this.$refs.map.updateSize();
    },
    layerId(nV, oV) {
      if (!nV && nV !== 0 && (oV || oV === 0)) {
        this.$store.set('map/DELETE_SELECTED_FEATURES!', {
          type: 'selectedFeatures',
          features: {
            [oV]: [],
          },
        });
        if (this.layersFilters[oV]) {
          this.$root.$emit('filterProjectLayer', oV, this.layersFilters[oV].filterExpression);
        }
      }
    },
    // mapPanelDataFetched: {
    //   Watcher updates the attributes to be requested in the mvt tiles after initial data fetched,
    //   but causes the tiles to blink ugly at map start.
    //   Disabled for now, because cache is always used and return all attributes.
    //   Uncomment if there is a problem with missing attributes in tiles.
    //   immediate: true,
    //   handler(nV) {
    //     if (nV) {
    //       this.projectLayers.forEach(layer => {
    //         if (layer.has_permission && layer.type === 'features_layer' && layer.supports_mvt) {
    //           this.$root.$emit('setProjectLayerStyle', layer.id);
    //         }
    //       });
    //     }
    //   },
    // },
  },
  methods: {
    /**
     * Get all the actions from Vuex layers module.
     */
    getLayers: call('layers/getLayers'),
    getDataSources: call('layers/getLayersMetadata'),
    getLayersSchema: call('layers/getLayersSchema'),
    getProjects: call('layers/getProjects'),
    getProject: call('layers/getProject'),
    getUsers: call('users/getUsers'),
    getSettings: call('admin/getSettings'),
    getModulesMapping: call('admin/getModulesMapping'),
    getAdditionalModules: call('admin/getAdditionalModules'),
    getCoordinateSystems: call('map/getCoordinateSystems'),
    getBackendVersion: call('admin/getBackendVersion'),
    getAllRelationValuesMapping: call('attributesSchema/getAllRelationValuesMapping'),
    getCacheLayersMetadata: call('layers/getCacheLayersMetadata'),
    getRelations: call('relations/getRelations'),
    /**
     * Get required data to initialize application, then get layers.
     * Required data: permissions, application theme.
     */
    async init() {
      this.mapPanelDataFetched = false;
      this.mapRelationDataFetched = false;
      try {
        this.setProjectId();
        await this.fetchInitialData();
        document.title = this.appName || 'AQ3.0';
        await Promise.all([
          this.getLayersSchema(),
          this.getDataSources({ with_attributes_schema: true, withAdditionalMetadata: true }),
        ]);
        this.mapPanelDataFetched = true;
        // fetchProject potrzebuje metadanych warstwy żeby pobrać odpowiednie atrybuty z kafli
        await this.fetchProject();
        this.$root.$emit('updateLegendStylesCounts');
        await this.fetchRelationsData();
        this.$root.$emit('socket.connect', { name: 'default' });
      } catch (error) {
        console.error(error);
      }
    },
    setProjectId() {
      if (!this.hasProjectQuery) {
        this.projectId = this.getProjectIdFromLs();
        this.setUrl();
      } else {
        this.projectId = this.queryProjectId;
      }
    },
    getProjectIdFromLs() {
      const usersProjects = JSON.parse(localStorage.getItem('usersProjects'));
      return usersProjects && usersProjects[this.currentUserId] ? usersProjects[this.currentUserId] : 'default';
    },
    async fetchRelationsData() {
      await this.getAllRelationValuesMapping();
      this.mapRelationDataFetched = true;
    },
    async fetchInitialData() {
      await Promise.all([
        this.getProjects({ with_default: true }),
        this.getLayers(),
        this.getUsers(),
        this.getModulesMapping(),
        this.getSettings(),
        this.getAdditionalModules(),
        this.getCoordinateSystems(),
        this.getBackendVersion(),
      ]);
      if (this.isCacheLayersEnabled) {
        await this.getCacheLayersMetadata();
      }
    },
    async setInitRelationFilter(relationDS, relationFid) {
      const currentDataSourceName = this.$store.get('layers/layers')[this.layerId]?.data_source_name;
      const currentIdAttributeName =
        this.$store.get('layers/metadata')[currentDataSourceName]?.attributes_schema.id_name;
      const ids =
        (
          await this.getRelations({
            data_source_name: relationDS,
            feature_id: relationFid,
            returnSimpleRelations: true,
          })
        )
          ?.filter(relation => relation.data_source_name === currentDataSourceName)
          ?.map(relation => `${relation.feature_id}`) || [];
      if (ids) {
        const filterExpression = { $IN: { [`${currentDataSourceName}.${currentIdAttributeName}`]: { value: ids } } };
        const filters = {
          builder_filter: filterExpression,
          headers_filter: {},
          search_filter: '',
          navbar_filter: {},
          sidebar_filter: {},
        };
        this.$store.set('layers/SET_LAYERS_FILTERS!', { layerId: this.layerId, filterExpression, filters });
        this.$router.replace({
          query: { ...this.$_filterObjectKeys(this.$route.query, { notAllowedKeys: ['relations_filter'] }) },
        });
        if (!this.projectLayers.some(layer => layer.id === this.layerId)) {
          const layer = this.$store.get('layers/layers')[this.layerId];
          if (layer) {
            layer.labels_visible = layer.labels_visible || false;
            layer.style = layer.style_web;
            delete layer.style_web;
            return { layer_id: layer.id, visible: true, opacity: 1, has_permission: true, ...layer };
          }
        }
      }
    },
    async fetchProject({ withRouterParse = true } = {}) {
      try {
        if (withRouterParse) {
          await this.getProject({ id: this.projectId });
        }
        const project = JSON.parse(JSON.stringify(this.projects[this.projectId]));
        this.$store.set('layers/SET_PROJECT!', project);
        this.$store.set('layers/SET_UNCHANGED_PROJECT!', project);
        if (this.$route.query.Z) this.$store.set('map/SET_ZOOM!', parseFloat(this.$route.query.Z));
        if (this.$route.query.X && this.$route.query.Y)
          this.$store.set('map/SET_CENTER!', [parseFloat(this.$route.query.X), parseFloat(this.$route.query.Y)]);
        if (this.$route.query.Z || this.$route.query.X || this.$route.query.Y) this.setUrl(false, true);
        let layerToAddToProject;
        if (this.$route.query.relations_filter) {
          const [relationDS, relationFid] = this.$route.query.relations_filter.split('.');
          if (relationDS && (relationFid || relationFid === 0)) {
            try {
              layerToAddToProject = await this.setInitRelationFilter(relationDS, relationFid);
            } catch {
              //
            }
          }
        }
        this.isFetched = true;
        await this.$nextTick();
        this.$root.$emit('updateLegendStylesCounts');
        this.$root.$emit('projectLayersLoaded');
        if (this.projectId === 'default') {
          this.isProjectLayersFetched = true;
        }
        this.$nextTick(() => {
          if (layerToAddToProject) {
            this.$store.set('layers/ADD_PROJECT_LAYERS!', [layerToAddToProject]);
            this.$root.$emit('pushProjectLayers', [layerToAddToProject]);
            this.$store.set('snackbar/PUSH_MESSAGE!', {
              message: this.$i18n.t('snackbar.layerAddedToProject', { name: layerToAddToProject.name }),
            });
          }
        });
      } catch (error) {
        if (!this.isTriedDefault) {
          this.projectId = 'default';
          this.setUrl();
          this.isTriedDefault = true;
          await this.fetchProject();
        } else {
          this.$router.push({ name: 'map' });
          this.isFetched = true;
        }
      }
    },
    setUrl(stayOnLegend = false, removeCustomView = false) {
      const currentQuery = JSON.parse(JSON.stringify(this.$route.query));
      if (removeCustomView) {
        delete currentQuery.X;
        delete currentQuery.Y;
        delete currentQuery.Z;
      }
      this.$router
        .replace({
          ...this.$route,
          query: {
            ...currentQuery,
            project: this.projectId,
          },
          params: {
            stayOnLegend,
          },
        })
        .catch(error => {
          console.log('error', error);
        });
    },
    onFetchProject({ withRouterParse = true } = {}) {
      this.$root.$emit('toggleProjectLayersVisible', false);
      this.projectId = this.project?.id;
      if (withRouterParse) this.setUrl(true);
      this.fetchProject({ withRouterParse });
    },
  },
  created() {
    this.init();
  },
  mounted() {
    if (this.$route.name === 'map' && !Object.keys(this.$route.query).find(key => key !== 'project')) {
      const lastPath = localStorage.getItem('last_path_map');
      if (lastPath) this.$router.push(lastPath);
    }
    this.initShortcuts();
    this.$root.$on('fetchProject', this.onFetchProject);
    this.$once('hook:beforeDestroy', () => {
      this.$root.$off();
    });
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
  overflow-y: hidden;
}

.printable-map-wrapper {
  display: none;
}

.navbar {
  flex: 0 0 10%;
  position: relative;
}

.centerTrick {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content {
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  position: relative;
  width: 100%;
  height: 100%;
  align-items: center;
}

.map-legend-visible::before {
  width: calc(100dvw - #{$sidebar-width__expanded}) !important;
}

.map-legend-hidden::before {
  width: calc(100dvw - #{$sidebar-width__collapsed}) !important;
}

.map-overlay {
  flex-grow: 1;
}

.map-overlay::before {
  content: '';
  height: 100%;
  background: none;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  box-shadow: inset -4px 4px 7px rgba(0, 0, 0, 0.2);
  z-index: 3;
}
.legend__visible {
  width: $sidebar-width__expanded;
  min-width: $sidebar-width__expanded;
}
.legend__hidden {
  width: $sidebar-width__collapsed;
  min-width: $sidebar-width__collapsed;
}
.legend {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  position: relative;
}
</style>

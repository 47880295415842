import { sync } from 'vuex-pathify';

import { Draw, Modify, Snap, Translate } from 'ol/interaction';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { formatArea, formatLength, getGeometryCentroid } from '@/assets/js/mapUtils';
import { transform } from 'ol/proj.js';
import { circular } from 'ol/geom/Polygon.js';
import { getDistance, getLength } from 'ol/sphere.js';
import { KML, GeoJSON } from 'ol/format';
import { GeometryCollection } from 'ol/geom';
import Feature from 'ol/Feature';
import { MousePosition } from 'ol/control';

export default {
  computed: {
    measurementsGeometries: sync('map/measurementsGeometries'),
    measurementsIntersect: sync('map/measurementsIntersect'),
    isMeasurementsToolActive: sync('tools/toolStatus@isMeasurementsToolActive'),
    cursorPositionEpsg: sync('map/cursorPositionEpsg'),
  },
  data: () => ({
    typeStyleFunctionsDist: {
      Polygon: 'getAreaMeasurementStyles',
      MultiPolygon: 'getAreaMeasurementStyles',
      LineString: 'getLengthMeasurementStyles',
      MultiLineString: 'getLengthMeasurementStyles',
      Circle: 'getCircleMeasurementStyles',
      Intersect1: 'getIntersectMeasurementStyles1',
      Intersect2: 'getIntersectMeasurementStyles2',
    },
  }),
  methods: {
    clearMeasurementInteraction() {
      if (!this.map) return;
      [
        this.getInteractionByName('measurementDrawInteraction'),
        this.getInteractionByName('measurementModifyInteraction'),
        this.getInteractionByName('measurementSnapInteraction'),
        this.getInteractionByName('measurementTranslateInteraction'),
      ].every(interaction => interaction && this.map.removeInteraction(interaction));
      if (this.getLayerById('measurement')) this.map.removeLayer(this.getLayerById('measurement'));
      if (this.getLayerById('measurement-copy')) this.map.removeLayer(this.getLayerById('measurement-copy'));
      if (this.getLayerById('measurementGuides')) this.map.removeLayer(this.getLayerById('measurementGuides'));
      if (this.getLayerById('measurementIntersection1'))
        this.map.removeLayer(this.getLayerById('measurementIntersection1'));
      if (this.getLayerById('measurementIntersection2'))
        this.map.removeLayer(this.getLayerById('measurementIntersection2'));
      this.turnOffModifySnapping();
      this.measurementsGeometries = null;
      this.measurementsIntersect = null;
      this.isMeasurementsToolActive = false;
      this.activeTool = undefined;
      this.isActiveToolNotReplaceable = false;
    },
    getPolygonMeasurementLabel(geometry, intersect) {
      if (intersect) {
        return `${this.$i18n.t('map.intersectArea')}: ${formatArea(geometry)}
        ${this.$i18n.t('map.sumArea')}: ${intersect}`;
      } else {
        return `${this.$i18n.t('map.area')}: ${formatArea(geometry)}
        ${this.$i18n.t('map.perimeter')}: ${formatLength(geometry)}`;
      }
    },
    getMeasurementStyle(feature, type, disablePointStyle = false, params) {
      const geometry = feature.getGeometry();
      return [
        ...(disablePointStyle ? [] : this.getPointMeasurementStyle({ geometry })),
        ...this[this.typeStyleFunctionsDist[type]]({ geometry, feature, ...params }),
      ];
    },
    addMeasurementLayer(type, { disableTranslate = false, showArrowsOnEndPoints = false, staticCopy = false } = {}) {
      const layer = new VectorLayer({
        source: new VectorSource({}),
        id: `measurement${staticCopy ? '-copy' : ''}`,
        style: feature => this.getMeasurementStyle(feature, type, false, { disableTranslate, showArrowsOnEndPoints }),
        zIndex: 1000,
      });
      this.map.addLayer(layer);
      return layer;
    },
    getMeasurementModifyStyleHandler(feature, isCircle, type) {
      if (isCircle) {
        feature.get('features').forEach(modifyFeature => {
          const modifyGeometry = modifyFeature.get('modifyGeometry');
          if (modifyGeometry) {
            const modifyPoint = feature.getGeometry().getCoordinates();
            const geometries = modifyFeature.getGeometry().getGeometries();
            const polygon = geometries[0].getCoordinates()[0];
            const center = geometries[1].getCoordinates();
            const projection = this.map.getView().getProjection();
            let first, last, radius;
            if (modifyPoint[0] === center[0] && modifyPoint[1] === center[1]) {
              first = transform(polygon[0], projection, 'EPSG:4326');
              last = transform(polygon[(polygon.length - 1) / 2], projection, 'EPSG:4326');
              radius = getDistance(first, last) / 2;
            } else {
              first = transform(center, projection, 'EPSG:4326');
              last = transform(modifyPoint, projection, 'EPSG:4326');
              radius = getDistance(first, last);
            }
            const circle = circular(transform(center, projection, 'EPSG:4326'), radius, 128);
            circle.transform('EPSG:4326', projection);
            geometries[0].setCoordinates(circle.getCoordinates());
            modifyGeometry.setGeometries(geometries);
          }
        });
        return this.getMeasurementStyle(feature, type, true);
      } else {
        return this.getMeasurementStyle(feature, type, true);
      }
    },
    addMeasurement(type, subtype) {
      if (!this.map) return;
      this.measurementsGeometries = null;
      this.measurementsIntersect = null;
      const drawLayer = this.addMeasurementLayer(type, { showArrowsOnEndPoints: subtype === 'measures' });
      this.addMeasurementInteraction(drawLayer, type, subtype);
    },
    getMeasurementGeometryGeojson() {
      const measurementFeaturesArray = this.getLayerById('measurement')?.getSource().getFeatures() || [];
      const measurementCopyFeaturesArray = this.getLayerById('measurement-copy')?.getSource().getFeatures() || [];
      return JSON.stringify({
        ...new GeoJSON().writeFeaturesObject(
          [...measurementFeaturesArray, ...measurementCopyFeaturesArray].map(f => {
            const geom = f.getGeometry();
            return geom instanceof GeometryCollection ? new Feature({ geometry: geom.getGeometries()[0] }) : f;
          })
        ),
        crs: {
          properties: {
            name: this.$_config.defaultEpsg,
          },
          type: 'name',
        },
      });
    },
    addMeasurementInteraction(layer, type, subtype) {
      this.deactivateToolHandler();
      const isCircle = type === 'Circle';
      const drawInteraction = new Draw({
        source: layer.getSource(),
        ...(isCircle && {
          geometryFunction: (coords, geom, proj) => this.createPolygonFromCircle(coords, geom, proj, true),
        }),
        type: type,
        style: feature => this.getMeasurementStyle(feature, type),
        ...(subtype === 'measures' ? { maxPoints: 2 } : {}),
      });
      drawInteraction.set('name', 'measurementDrawInteraction');
      drawInteraction.on('drawstart', () => {
        this.measurementsIntersect = null;
      });
      drawInteraction.on('drawend', () => {
        this.measurementsIntersect = null;
        setTimeout(() => {
          this.measurementsGeometries = this.getMeasurementGeometryGeojson();
          if (this.activeTool === 'measurementMeasures') {
            this.addMeasurementStaticCopyAndGuides();
            this.map.removeInteraction(drawInteraction);
          }
        });
      });
      let modifyInteraction;
      if (subtype !== 'measures') {
        modifyInteraction = new Modify({
          source: layer.getSource(),
          style: feature => this.getMeasurementModifyStyleHandler(feature, isCircle, type),
        });
        modifyInteraction.set('name', 'measurementModifyInteraction');
        modifyInteraction.on('modifystart', () => {
          this.measurementsIntersect = null;
        });
        modifyInteraction.on('modifyend', () => {
          this.measurementsIntersect = null;
          setTimeout(() => {
            this.measurementsGeometries = this.getMeasurementGeometryGeojson();
          });
        });
        if (isCircle) {
          modifyInteraction.on('modifystart', event => {
            event.features.forEach(feature => {
              const geometry = feature.getGeometry();
              if (geometry.getType() === 'GeometryCollection') feature.set('modifyGeometry', geometry.clone(), true);
            });
          });

          modifyInteraction.on('modifyend', event => {
            event.features.forEach(feature => {
              const modifyGeometry = feature.get('modifyGeometry');
              if (modifyGeometry) {
                feature.setGeometry(modifyGeometry);
                feature.unset('modifyGeometry', true);
              }
            });
          });
        }
      }

      const translateInteraction = new Translate({
        layers: [layer],
        filter: feature => {
          const mouseCoordinateInEpsg = this.map
            .getControls()
            .getArray()
            .find(c => c instanceof MousePosition)
            ?.element.innerText.split(', ')
            .map(c => +c);
          const mouseCoordinate = transform(
            mouseCoordinateInEpsg,
            this.cursorPositionEpsg,
            this.$_config.defaultEpsg || 'EPSG:3857'
          );
          const mousePixel = this.map.getPixelFromCoordinate(mouseCoordinate);
          const featureCoordinate = getGeometryCentroid(feature.getGeometry());
          const featurePixel = this.map.getPixelFromCoordinate(featureCoordinate);
          return Math.abs(mousePixel[0] - featurePixel[0]) <= 20 && Math.abs(mousePixel[1] - featurePixel[1]) <= 20;
        },
      });
      translateInteraction.set('name', 'measurementTranslateInteraction');
      translateInteraction.on('translatestart', () => {
        this.measurementsIntersect = null;
        if (subtype === 'measures') this.getLayerById('measurementGuides')?.getSource().clear();
      });
      translateInteraction.on('translateend', () => {
        setTimeout(() => {
          this.measurementsIntersect = null;
          this.measurementsGeometries = this.getMeasurementGeometryGeojson();
          if (subtype === 'measures') this.onMeasurementsMeasuresTranslation();
        });
      });

      let snapInteraction;
      if (subtype !== 'measures') {
        snapInteraction = new Snap({
          source: layer.getSource(),
        });
        snapInteraction.set('name', 'measurementSnapInteraction');
      }

      this.map.addInteraction(drawInteraction);
      if (modifyInteraction) this.map.addInteraction(modifyInteraction);
      this.map.addInteraction(translateInteraction);
      if (snapInteraction) this.map.addInteraction(snapInteraction);
      this.isMeasurementsToolActive = true;
    },
    onMeasurementsMeasuresTranslation() {
      const measurementsGeometries = JSON.parse(this.measurementsGeometries);
      measurementsGeometries.features = measurementsGeometries.features.map((feature, index) => {
        const geom = new GeoJSON().readGeometry(feature.geometry, {
          featureProjection: this.$_config.defaultEpsg || 'EPSG:4326',
          dataProjection: this.$_config.defaultEpsg || 'EPSG:4326',
        });
        return {
          ...feature,
          properties: {
            ...feature.properties,
            [this.$i18n.t('navbar.measurementMeasures.typeAttribute')]: index
              ? this.$i18n.t('navbar.measurementMeasures.measure')
              : this.$i18n.t('navbar.measurementMeasures.measureOffset'),
            [this.$i18n.t('navbar.measurementMeasures.lengthAttribute')]: +getLength(geom).toFixed(2),
          },
        };
      });
      const [
        {
          geometry: { coordinates: mainCoordinates },
        },
        {
          geometry: { coordinates: copyCoordinates },
        },
      ] = measurementsGeometries.features;
      [0, 1].forEach(i => {
        const feature = {
          geometry: { coordinates: [mainCoordinates[i], copyCoordinates[i]], type: 'LineString' },
          properties: {
            [this.$i18n.t('navbar.measurementMeasures.typeAttribute')]: this.$i18n.t(
              'navbar.measurementMeasures.runner'
            ),
          },
          type: 'Feature',
        };
        const geom = new GeoJSON().readGeometry(feature.geometry, {
          featureProjection: this.$_config.defaultEpsg || 'EPSG:4326',
          dataProjection: this.$_config.defaultEpsg || 'EPSG:4326',
        });
        feature.properties[this.$i18n.t('navbar.measurementMeasures.lengthAttribute')] = +getLength(geom).toFixed(2);
        measurementsGeometries.features.push(feature);
      });
      this.measurementsGeometries = JSON.stringify(measurementsGeometries);
      const guidesLayer = this.getLayerById('measurementGuides');
      const features = new GeoJSON().readFeatures(
        {
          ...measurementsGeometries,
          features: [measurementsGeometries.features[2], measurementsGeometries.features[3]],
        },
        {
          featureProjection: measurementsGeometries.crs?.properties?.name || this.$_config.defaultEpsg || 'EPSG:4326',
          dataProjection: measurementsGeometries.crs?.properties?.name || this.$_config.defaultEpsg || 'EPSG:4326',
        }
      );
      guidesLayer.getSource().clear();
      guidesLayer.getSource().addFeatures(features);
    },
    addMeasurementStaticCopyAndGuides() {
      const guidesLayer = new VectorLayer({
        source: new VectorSource({}),
        id: 'measurementGuides',
        style: feature =>
          this.getMeasurementStyle(feature, 'LineString', false, { disableTranslate: true, slim: true }),
        zIndex: 1001,
      });
      this.map.addLayer(guidesLayer);
      const copyLayer = this.addMeasurementLayer('LineString', {
        disableTranslate: true,
        staticCopy: true,
      });
      const geojson = JSON.parse(this.measurementsGeometries);
      const features = new GeoJSON().readFeatures(geojson, {
        featureProjection: geojson.crs?.properties?.name || this.$_config.defaultEpsg || 'EPSG:4326',
        dataProjection: geojson.crs?.properties?.name || this.$_config.defaultEpsg || 'EPSG:4326',
      });
      copyLayer.getSource().addFeatures(features);
    },
    turnOffMeasurementsInteraction() {
      this.map.removeInteraction(this.getInteractionByName('measurement'));
    },
    addMeasurementIntersectionResult(value) {
      const layersD = [
        { name: 'measurementIntersection1', type: 'Intersect1', zIndex: 999 },
        { name: 'measurementIntersection2', type: 'Intersect2', zIndex: 1001 },
      ];
      layersD.forEach(({ name, type, zIndex }) => {
        let layer = this.getLayerById(name);
        if (layer) layer.getSource().clear();
        else {
          layer = new VectorLayer({
            source: new VectorSource({}),
            id: name,
            style: feature => this.getMeasurementStyle(feature, type),
            zIndex,
          });
          this.map.addLayer(layer);
        }
        if (value) {
          const area = value.properties?.union_area;
          let formattedArea = `${area.toFixed(2)} m2`;
          if (area > 1000000) {
            formattedArea = `${(area / 1000000).toFixed(2)} km2`;
          } else if (area > 10000) {
            formattedArea = `${(area / 10000).toFixed(2)} ha`;
          }

          const feature = new GeoJSON().readFeature(value, {
            featureProjection: this.$_config.defaultEpsg || 'EPSG:3857',
            dataProjection: this.$_config.defaultEpsg || 'EPSG:3857',
          });
          feature.set('union_area', formattedArea);
          layer.getSource().addFeature(feature);
        }
      });
    },
  },
  watch: {
    measurementsIntersect: {
      handler(nV) {
        if (!this.activeTool?.includes('measurement')) return;
        this.addMeasurementIntersectionResult(nV);
      },
      deep: true,
    },
  },
  mounted() {
    /**
     * Methods with parameters has to be wrapped in order to not be executed
     * during proccess of attachment.
     */
    this.$root.$on('measurementArea-action', (value = true) => {
      if (value) {
        this.addMeasurement('Polygon');
        this.activeTool = 'measurementArea';
      } else {
        this.clearMeasurementInteraction();
      }
    });
    this.$root.$on('measurementLength-action', (value = true) => {
      if (value) {
        this.addMeasurement('LineString');
        this.activeTool = 'measurementLength';
      } else {
        this.clearMeasurementInteraction();
      }
    });
    this.$root.$on('measurementRadius-action', (value = true) => {
      if (value) {
        this.addMeasurement('Circle');
        this.activeTool = 'measurementRadius';
      } else {
        this.clearMeasurementInteraction();
      }
    });
    this.$root.$on('measurementMeasures-action', (value = true) => {
      if (value) {
        this.addMeasurement('LineString', 'measures');
        this.initModifySnapping();
        this.activeTool = 'measurementMeasures';
      } else {
        this.clearMeasurementInteraction();
      }
    });
    this.$root.$on('exportMeasurement', type => {
      const measurementsGeometries = JSON.parse(this.measurementsGeometries);
      const contentString =
        type === 'kml'
          ? `<?xml version="1.0" encoding="utf-8" ?>${new KML().writeFeatures(
              new GeoJSON().readFeatures(measurementsGeometries),
              {
                featureProjection: this.$_config.defaultEpsg || 'EPSG:4326',
                dataProjection: 'EPSG:4326',
              }
            )}`
          : JSON.stringify(measurementsGeometries);
      const file = new Blob([contentString], {
        type: `${type === 'kml' ? 'text/xml' : 'application/geo+json'};charset=utf-8;`,
      });
      this.$_saveFile(URL.createObjectURL(file), `${this.$i18n.t('sidebar.measurements')}.${type}`);
    });
  },
};
